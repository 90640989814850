.selected {
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
  cursor: default;
}

.non-selected {
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #333;
  }
}

.commentList {
  width: 100%;
  height: calc(100% - 96px);
}

.MuiAccordionSummary-content.Mui-expanded,
.MuiAccordionSummary-content {
  margin: 0 !important;
}
